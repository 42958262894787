import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { Heading, Text, Box } from '@magalu/stereo-ui/atoms';
import { StarGroup } from '@magalu/stereo-ui-icons';
import { useProductQAsHighlights } from '@magalu/mixer-graphql';
import withLayoutProps from '../../../hocs/withLayoutProps';
function ProductQAHighlights(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data,
    id = _ref.id;
  var title = staticProps.title,
    aiDescription = staticProps.aiDescription;
  var variationId = data.product.variationId;
  var _useProductQAsHighlig = useProductQAsHighlights(),
    productQAsHighlights = _useProductQAsHighlig.productQAsHighlights,
    error = _useProductQAsHighlig.error,
    isLoading = _useProductQAsHighlig.isLoading,
    retrieveProductQAsHighlightsBy = _useProductQAsHighlig.retrieveProductQAsHighlightsBy;
  useEffect(function () {
    return variationId && retrieveProductQAsHighlightsBy(variationId);
  }, [variationId]);
  if (!variationId || isLoading || error || productQAsHighlights === null) {
    return null;
  }
  return React.createElement(Box, {
    "data-testid": id,
    id: id,
    maxWidth: "600px",
    mt: 40,
    my: [2, 2, 3, 3]
  }, React.createElement(Heading, {
    as: "h2",
    color: "base",
    fontSize: 5,
    fontWeight: "medium",
    my: [2, 2, 3, 3]
  }, React.createElement("span", {
    "aria-hidden": "true"
  }, "\uD83E\uDD29"), React.createElement("span", {
    title: "Emoji de um rosto, em que os olhos t\xEAm formatos de estrelas em vermelho."
  }, " ".concat(title))), productQAsHighlights == null ? void 0 : productQAsHighlights.map(function (_ref2) {
    var summary = _ref2.summary,
      topic = _ref2.topic;
    return React.createElement(Box, {
      key: topic
    }, React.createElement(Heading, {
      as: "h4",
      color: "base",
      fontSize: 2,
      fontWeight: "bolder",
      mt: 4,
      mb: 2
    }, topic), React.createElement(Text, {
      fontWeight: "medium",
      lineHeight: 1.25
    }, summary));
  }), React.createElement(Box, {
    mt: 4,
    display: "flex"
  }, React.createElement(Box, null, React.createElement(StarGroup, {
    fill: "#999"
  })), React.createElement(Box, {
    ml: 2
  }, React.createElement(Text, {
    color: "#999",
    fontSize: 1,
    lineHeight: 1.25
  }, aiDescription))));
}
process.env.NODE_ENV !== "production" ? ProductQAHighlights.propTypes = {
  data: propTypes.shape({
    product: propTypes.shape({
      variationId: propTypes.string
    })
  }),
  id: propTypes.string,
  "static": propTypes.shape({
    aiDescription: propTypes.string,
    title: propTypes.string
  })
} : void 0;
ProductQAHighlights.defaultProps = {
  data: {
    product: {
      variationId: ''
    }
  },
  id: '',
  "static": {
    aiDescription: '',
    title: ''
  }
};
ProductQAHighlights.ssr = true;
export default withLayoutProps(ProductQAHighlights);